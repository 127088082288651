<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Организации</h4>
      <AddOrganization/>

      <DataTable :value="organizations" showGridlines stripedRows responsiveLayout="scroll"
                 :rows="10" :paginator="true" :rowsPerPageOptions="[10,20,50]">

        <Column field="name" header="Название"></Column>
        <Column field="type" header="Тип"></Column>

        <Column header="Лидер">
          <template #body="{data}">
            {{ data.leader_id.last_name }} {{ data.leader_id.first_name }} - GPA {{ data.leader_id.gpa }}
          </template>
        </Column>

        <Column field="profile" header="Профиль"></Column>

        <Column header="Статус">
          <template #body="{data}">
            <select :id="data.id" @change="setStatusId($event, data.id)">
              <option v-for="(status, index) in statuses" :key="index" :selected="status.id === Number(data.status_id)" :value="status.id">{{ status.name }}</option>
            </select>
            <button class="btn btn-primary ms-1" @click="sendStatusId(data.id)">Сохранить</button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>


<script>

import {mapActions, mapMutations, mapState} from 'vuex'
import AddOrganization from "@/components/organization/AddOrganization.vue";

export default {
  name: "Organizations",
  components: {
    AddOrganization
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('organization', ['organizations', 'types', 'profiles', 'statuses']),
  },
  methods: {
    ...mapActions('organization', ['GET_TYPES', 'GET_PROFILES', 'POST_ORGANIZATION', 'GET_STUDENT_BY_BARCODE', 'GET_ORGANIZATIONS', 'GET_STATUSES', 'PUT_ORGANIZATION_STATUS']),
    ...mapMutations('organization', ['SET_STATUS_ID']),

    setStatusId(event, id){
      this.SET_STATUS_ID({id: id, status_id: event.target.value})
    },

    async sendStatusId(id){
      await this.PUT_ORGANIZATION_STATUS(id)
    }
  },
  async mounted() {
    await this.GET_ORGANIZATIONS()
    await this.GET_TYPES()
    await this.GET_PROFILES()
    await this.GET_STATUSES()

    this.loading = false
  }
}
</script>

<style scoped>

</style>
