<template>
  <div>
    <div class="my-4">
      <Button icon="pi pi-plus" label="Добавить организацию" @click="openOrganization"/>
    </div>


    <Dialog :header="'Добавление организации'"
            :visible="displayOrganization" :modal="true" :closable="false"
            :style="{width: '100%', maxWidth: '700px'}">
      <div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Тип</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="organizationModel.organization_type_id">
              <option v-for="(type, typeIndex) in types" :key="typeIndex" :value="type.id">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Название</label>
          <div class="col-md-9">
            <input type="text" class="form-control" placeholder="Название" v-model="organizationModel.name">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Профиль</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="organizationModel.organization_profile_id">
              <option v-for="(profile, profileIndex) in profiles" :key="profileIndex" :value="profile.id">
                {{ profile.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Описание</label>
          <div class="col-md-9">
            <textarea type="text" class="form-control" v-model="organizationModel.description"></textarea>
          </div>
        </div>


        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Лидер</label>
          <div class="col-md-5">
            <input type="text" class="form-control" placeholder="Поиск" v-model="leaderBarcode">
          </div>
          <div class="col-md-4">
            <Button label="Найти" @click="searchStudent()"/>
          </div>
          <div v-if="leader" class="ms-2 mt-3 row">
            <div class="col-md-5">
              {{ leader.last_name }} {{ leader.first_name }} - GPA {{ leader.gpa }}
            </div>
            <div class="col-md-3">
              <Button v-if="organizationModel.leader_id!==leader.id" icon="pi pi-check" @click="selectStudent()"
                      class="p-button-rounded p-button-success"/>
              <div v-else>Выбран</div>
            </div>
          </div>
        </div>

        <div class="form-group row mt-4">

          <label class="col-md-3 col-form-label">Участники</label>
          <div class="col-md-5">
            <input type="text" class="form-control" placeholder="Поиск" v-model="memberBarcode">
          </div>
          <div class="col-md-4">
            <Button label="Найти" @click="searchStudent('member')"/>
          </div>

          <div v-if="member" class="ms-2 mt-3 py-1 row bg-light rounded-3">
            <div class="col-md-7">
              {{ member.last_name }} {{ member.first_name }} - GPA {{ member.gpa }}
            </div>
            <div class="col-md-2">
              <Button icon="pi pi-check" @click="selectStudent('member')" class="p-button-rounded p-button-success"/>
            </div>
          </div>


          <div v-if="members.length" class="ps-4 mt-3">
            <div class="my-2 fw-bold">Выбранные участники</div>
            <div v-for="(m, mIndex) in members" :key="mIndex" class="ms-2 row my-2">
              <div class="col-md-7">
                {{ mIndex + 1 }}. {{ m.last_name }} {{ m.first_name }} - GPA {{ m.gpa }}
              </div>
              <div class="col-md-2">
                <Button icon="pi pi-trash" @click="deleteMember(mIndex)" class="p-button-rounded p-button-danger"/>
              </div>
            </div>
          </div>

        </div>


      </div>
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeOrganization" class="p-button-text"/>
        <Button label="Добавить" icon="pi pi-plus" autofocus @click="postOrganization"/>
      </template>
    </Dialog>

  </div>
</template>


<script>

import {mapActions, mapState} from 'vuex'


export default {
  name: "AddOrganization",
  data() {
    return {
      loading: true,
      displayOrganization: false,
      disciplineModel: {},
      organizationModel: {},
      leaderBarcode: '',
      leader: null,

      memberBarcode: '',
      member: null,
      members: [],

    }
  },
  computed: {
    ...mapState('organization', ['organizations', 'types', 'profiles']),
  },
  methods: {
    ...mapActions('organization', ['GET_TYPES', 'GET_PROFILES', 'POST_ORGANIZATION', 'GET_STUDENT_BY_BARCODE', 'GET_ORGANIZATIONS']),
    openOrganization() {
      this.organizationModel = {
        organization_type_id: 1,
        name: '',
        organization_profile_id: null,
        description: ''
      }

      this.leaderBarcode = ''
      this.leader = null

      this.memberBarcode = ''
      this.member = null
      this.members = []

      this.displayOrganization = true
    },
    closeOrganization() {
      this.displayOrganization = false
    },
    async searchStudent(type = 'leader') {
      let barcode = type === 'leader' ? this.leaderBarcode : this.memberBarcode
      const student = await this.GET_STUDENT_BY_BARCODE(barcode)

      if (type === 'leader') {
        this.leader = student
      } else {
        this.member = student
      }
    },
    selectStudent(type = 'leader') {
      if (type === 'leader') {
        this.organizationModel.leader_id = this.leader.id
      } else {
        this.members.push(this.member)
        this.memberBarcode = ''
        this.member = null
      }
    },
    deleteMember(index) {
      this.members.splice(index, 1)
    },
    async postOrganization() {
      const form = {
        ...this.organizationModel,
        members: this.members.map(i => i.id)
      }

      const res = await this.POST_ORGANIZATION(form)
      if (res) {
        await this.GET_ORGANIZATIONS()
        this.closeOrganization()
        this.$message({title: 'Организация успешно добавлена'})
      }
    }
  },
}
</script>

<style scoped>

</style>
